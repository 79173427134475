import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  position: relative;
  width: 36px;
  height: 36px;
  display: inline-block;
  margin-right: 5px;
`
const Logo = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="#abfdff" d="M200.628 288.585h15.453v116.122h-15.453z" />
        <path
          d="M230.774 395.329c0 12.382-10.037 50.79-22.419 50.79s-22.419-38.408-22.419-50.79c0-12.382 10.037-22.419 22.419-22.419 12.381 0 22.419 10.037 22.419 22.419z"
          fill="#cc7400"
        />
        <path
          d="M185.935 395.329c0 12.382 10.037 50.79 22.419 50.79V372.91c-12.382 0-22.419 10.037-22.419 22.419z"
          fill="#aa6100"
        />
        <path
          d="M390.545 5.809L359.27.405a27.734 27.734 0 00-17.52 2.725L78.318 140.179l33.043 54.856L371.246 59.83a27.731 27.731 0 0012.288-12.782l13.526-28.712c2.499-5.303-.739-11.529-6.515-12.527z"
          fill="#d1e7f8"
        />
        <path
          d="M396.905 10.458a8.824 8.824 0 00-6.361-4.649L359.269.405a27.734 27.734 0 00-17.52 2.725L78.318 140.179l16.852 27.257L396.905 10.458z"
          fill="#b9d8f4"
        />
        <path
          d="M383.534 47.047l13.526-28.712c2.498-5.303-.741-11.528-6.517-12.526L359.268.405a27.734 27.734 0 00-17.52 2.725l-59.893 31.159a27.735 27.735 0 0117.52-2.725l31.275 5.404c5.776.998 9.015 7.224 6.517 12.526l-13.526 28.712a27.726 27.726 0 01-12.288 12.782l59.893-31.159a27.717 27.717 0 0012.288-12.782z"
          fill="#57e5d0"
        />
        <path
          d="M390.545 5.809L359.27.405a27.734 27.734 0 00-17.52 2.725l-59.893 31.159a27.735 27.735 0 0117.52-2.725l31.275 5.404a8.82 8.82 0 016.361 4.649l59.893-31.159a8.83 8.83 0 00-6.361-4.649z"
          fill="#28c6af"
        />
        <path
          d="M446.726 97.407l-26.551-17.39a27.736 27.736 0 00-17.16-4.463L156.449 93.08l4.48 63.757 246.617-17.53a27.729 27.729 0 0016.356-6.846l23.824-20.97c4.401-3.873 3.903-10.872-1-14.084z"
          fill="#96c8ef"
        />
        <path
          d="M450.715 104.201a8.826 8.826 0 00-3.99-6.794l-26.551-17.39a27.736 27.736 0 00-17.16-4.463L156.449 93.08l2.24 31.879 292.026-20.758z"
          fill="#78b9eb"
        />
        <path
          d="M423.902 132.462l23.824-20.97c4.4-3.873 3.902-10.873-1.001-14.085l-26.551-17.39a27.736 27.736 0 00-17.16-4.463l-67.343 4.786a27.723 27.723 0 0117.16 4.463l26.551 17.392c4.904 3.212 5.401 10.211 1.001 14.085l-23.824 20.97a27.738 27.738 0 01-16.356 6.846l67.343-4.786a27.75 27.75 0 0016.356-6.848z"
          fill="#ff5c5c"
        />
        <path
          d="M446.726 97.407l-26.551-17.39a27.736 27.736 0 00-17.16-4.463l-67.343 4.786a27.723 27.723 0 0117.16 4.463l26.55 17.392a8.826 8.826 0 013.99 6.794l67.343-4.786a8.825 8.825 0 00-3.989-6.796z"
          fill="#f33"
        />
        <path
          d="M459.668 204.661l-18.055-26.104a27.727 27.727 0 00-14.219-10.592L176.442 86.23l-22.597 59.859 253.756 82.647c5.771 1.88 12 1.814 17.73-.188l29.965-10.464c5.533-1.93 7.707-8.603 4.372-13.423z"
          fill="#d1e7f8"
        />
        <path
          d="M460.807 212.456a8.827 8.827 0 00-1.139-7.796l-18.055-26.103a27.727 27.727 0 00-14.219-10.592L176.442 86.23l-11.299 29.93 295.664 96.296z"
          fill="#b9d8f4"
        />
        <path
          d="M425.331 228.55l29.965-10.464c5.534-1.933 7.707-8.605 4.373-13.425l-18.055-26.104a27.727 27.727 0 00-14.219-10.592l-64.194-20.908a27.738 27.738 0 0114.219 10.592l18.055 26.104c3.335 4.821 1.161 11.494-4.373 13.425l-29.965 10.464a27.735 27.735 0 01-17.73.188l64.194 20.908a27.749 27.749 0 0017.73-.188z"
          fill="#ffeb99"
        />
        <path
          d="M459.668 204.661l-18.055-26.104a27.727 27.727 0 00-14.219-10.592L363.2 147.057a27.738 27.738 0 0114.219 10.592l18.055 26.104a8.825 8.825 0 011.139 7.796l64.194 20.908a8.824 8.824 0 00-1.139-7.796z"
          fill="#ffde55"
        />
        <path
          d="M451.595 300.979l-12.026-29.371a27.734 27.734 0 00-11.612-13.4L153.844 97.109l-32.718 54.907L395.572 313.31a27.728 27.728 0 0017.356 3.625l31.512-3.784c5.821-.698 9.376-6.747 7.155-12.172z"
          fill="#96c8ef"
        />
        <path
          d="M451.034 308.837a8.824 8.824 0 00.561-7.859l-12.026-29.371a27.734 27.734 0 00-11.612-13.4L153.844 97.109l-16.359 27.453 313.549 184.275z"
          fill="#78b9eb"
        />
        <path
          d="M412.929 316.935l31.512-3.784c5.82-.698 9.376-6.749 7.155-12.174l-12.026-29.371a27.734 27.734 0 00-11.612-13.4l-58.205-34.208a27.734 27.734 0 0111.612 13.4l12.026 29.371c2.221 5.425-1.334 11.474-7.155 12.174l-31.512 3.784a27.735 27.735 0 01-17.356-3.625l58.205 34.208a27.733 27.733 0 0017.356 3.625z"
          fill="#57e5d0"
        />
        <path
          d="M451.595 300.979l-12.026-29.371a27.734 27.734 0 00-11.612-13.4L369.752 224a27.734 27.734 0 0111.612 13.4l12.026 29.371a8.825 8.825 0 01-.561 7.859l58.205 34.208a8.825 8.825 0 00.561-7.859z"
          fill="#28c6af"
        />
        <path
          d="M419.346 399.639l-3.572-31.537a27.723 27.723 0 00-7.523-16.055l-240.955-251.7-46.169 44.198 240.955 251.699a27.73 27.73 0 0015.712 8.216l31.352 4.943c5.79.913 10.859-3.939 10.2-9.764z"
          fill="#d1e7f8"
        />
        <path
          d="M416.664 407.048a8.823 8.823 0 002.681-7.409l-3.572-31.537a27.723 27.723 0 00-7.523-16.055l-240.954-251.7-23.084 22.099 272.452 284.602z"
          fill="#b9d8f4"
        />
        <path
          d="M377.794 404.46l31.352 4.943c5.791.913 10.859-3.939 10.2-9.764l-3.572-31.537a27.723 27.723 0 00-7.523-16.055l-46.687-48.769a27.732 27.732 0 017.523 16.055l3.572 31.537c.659 5.825-4.409 10.677-10.2 9.764l-31.352-4.943a27.728 27.728 0 01-15.712-8.216l46.687 48.769a27.73 27.73 0 0015.712 8.216z"
          fill="#ff5c5c"
        />
        <path
          d="M419.346 399.639l-3.572-31.537a27.723 27.723 0 00-7.523-16.055l-46.687-48.769a27.732 27.732 0 017.523 16.055l3.572 31.537a8.823 8.823 0 01-2.682 7.409l46.687 48.769a8.824 8.824 0 002.682-7.409z"
          fill="#f33"
        />
        <path
          d="M345.703 505.297l7.783-30.769a27.727 27.727 0 00-1.376-17.678L215.43 136.335l-58.791 25.072 136.68 320.515a27.731 27.731 0 0011.805 13.231l27.592 15.685c5.095 2.894 11.55.141 12.987-5.541z"
          fill="#96c8ef"
        />
        <path
          d="M340.581 511.283a8.823 8.823 0 005.122-5.986l7.783-30.769a27.734 27.734 0 00-1.376-17.678L215.43 136.335l-29.396 12.535 154.547 362.413z"
          fill="#78b9eb"
        />
        <path
          d="M305.122 495.15l27.592 15.685c5.096 2.897 11.55.144 12.988-5.538l7.783-30.769a27.734 27.734 0 00-1.376-17.678l-26.483-62.102a27.734 27.734 0 011.376 17.678l-7.783 30.769c-1.437 5.682-7.892 8.435-12.989 5.538l-27.592-15.685a27.736 27.736 0 01-11.805-13.231l26.483 62.102a27.74 27.74 0 0011.806 13.231z"
          fill="#ffeb99"
        />
        <path
          d="M345.703 505.297l7.783-30.769a27.734 27.734 0 00-1.376-17.678l-26.483-62.102a27.734 27.734 0 011.376 17.678l-7.783 30.769a8.823 8.823 0 01-5.122 5.986l26.483 62.102a8.821 8.821 0 005.122-5.986z"
          fill="#ffde55"
        />
        <path fill="#ff5c5c" d="M76.552 118.975L231.4 60.539v221.185z" />
        <path
          fill="#f33"
          d="M76.552 118.975L231.4 281.724v-80.191L132.764 97.762z"
        />
        <path
          d="M226.821 263.184l-124.35-138.426c-8.423-9.376-22.985-10.157-32.361-1.734l-11.782 10.584c-9.376 8.423-10.157 22.985-1.734 32.361l124.35 138.426c8.423 9.376 22.985 10.157 32.361 1.734l11.782-10.584c9.376-8.423 10.157-22.986 1.734-32.361z"
          fill="#57e5d0"
        />
        <path
          d="M197.947 311.949l17.328-60.473-71.707 12.459 36.346 40.459c4.739 5.277 11.424 7.831 18.033 7.555z"
          fill="#ffeb99"
        />
        <path
          d="M203.882 283.789L79.532 145.364c-6.667-7.422-7.545-18.085-2.98-26.388a22.647 22.647 0 00-6.443 4.049l-11.782 10.584c-9.376 8.423-10.157 22.985-1.734 32.361l124.35 138.426c8.423 9.376 22.985 10.157 32.361 1.734l11.782-10.584a22.669 22.669 0 004.714-5.973c-8.743 3.651-19.251 1.639-25.918-5.784z"
          fill="#28c6af"
        />
        <g fill="#ffeb99">
          <path d="M92.939 206.429l71.707-12.458-20.048 69.965zM51.009 147.231c-.981 6.543.845 13.461 5.585 18.738l36.346 40.459 20.047-69.966-61.978 10.769z" />
        </g>
        <g fill="#ffde55">
          <path d="M144.598 263.936l10.024-34.983-25.83-28.754-35.853 6.23 51.659 57.507z" />
          <path d="M102.963 171.446l-23.43-26.083c-.777-.864-1.447-1.784-2.069-2.728l-26.455 4.597c-.981 6.543.845 13.461 5.585 18.738l36.346 40.459 10.023-34.983zM203.882 283.789l-23.43-26.083-35.853 6.23 36.346 40.459c4.74 5.277 11.424 7.83 18.033 7.554l7.397-25.812c-.873-.718-1.716-1.483-2.493-2.348z" />
        </g>
        <circle cx="208.354" cy="288.585" r="44.741" fill="#f21c1c" />
        <circle cx="208.354" cy="288.585" r="22.019" fill="#57e5d0" />
        <path
          d="M208.354 288.583c0-8.146 4.435-15.241 11.01-19.049a21.884 21.884 0 00-11.01-2.97c-12.161 0-22.019 9.859-22.019 22.019s9.859 22.019 22.019 22.019c4.016 0 7.769-1.092 11.01-2.97-6.575-3.808-11.01-10.904-11.01-19.049z"
          fill="#28c6af"
        />
        <g fill="#ff728b">
          <path d="M86.059 170.575a8.286 8.286 0 01-6.226-.757 8.253 8.253 0 01-3.871-4.951 8.25 8.25 0 01.766-6.228 8.255 8.255 0 014.941-3.869 8.257 8.257 0 016.239.764 8.286 8.286 0 013.859 4.944 8.296 8.296 0 01-.754 6.236 8.292 8.292 0 01-4.954 3.861zM137.141 227.439a8.286 8.286 0 01-6.226-.757 8.253 8.253 0 01-3.871-4.951 8.25 8.25 0 01.766-6.228 8.25 8.25 0 014.941-3.869 8.257 8.257 0 016.239.764 8.286 8.286 0 013.859 4.944 8.296 8.296 0 01-.754 6.236 8.295 8.295 0 01-4.954 3.861z" />
        </g>
      </svg>
    </>
  )
}

const LogoWrapped = () => (
  <Wrapper>
    <Logo />
  </Wrapper>
)

export default LogoWrapped
